import { useState } from "react";
import {ReactComponent as Line} from "../assets/images/icon-line.svg";
import {ReactComponent as Linkedin} from "../assets/images/icon-linkedin.svg";

const Team = () => {
    const [show, setShow] = useState(-1);

    return (
        <section id="team" className="team">
          <h3 className="header-section">Our Team</h3>
          <Line className="line"/>
            <div className="team-content">
                <div className="content-profile" onMouseOver={() => setShow(0)} onMouseLeave={() => setShow(-1)}>
                    <img src="https://static.wixstatic.com/media/9b5be8_b97ba67197d443658203bc062e1d8674~mv2.jpg/v1/crop/x_251,y_0,w_1753,h_1761/fill/w_217,h_217,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Thomas_I.jpg"/>
                    {show === 0 && <div>
                        <p>Thomas Ingenhorst</p>
                        <label>CEO & Founder</label>
                        <a target="_blank" href="https://www.linkedin.com/in/thomas-ingenhorst-745ab6105/">
                        <Linkedin/>
                        </a>
                    </div>}
                </div>

                <div className="content-profile" onMouseOver={() => setShow(1)} onMouseLeave={() => setShow(-1)}>
                    <img src="https://static.wixstatic.com/media/9b5be8_daec871b8ef14f0e82d031f6a9bf53b4~mv2.png/v1/fill/w_217,h_216,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Dennis%20Mausbach%20(2).png"/>
                    {show === 1 && <div>
                        <p>Dennis Mausbach</p>
                        <label>CEO & Founder</label>
                        <a target="_blank" href="https://www.linkedin.com/in/dennismausbach/">
                         <Linkedin/>
                        </a>
                    </div>}
                    </div>

            </div>
       </section>
    )
}

export default Team;