import { useState } from "react";
import {ReactComponent as Line} from "../assets/images/icon-line.svg";

import React, { useEffect } from 'react';

const MeetUs = () => {
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
          'src',
          'https://assets.calendly.com/assets/external/widget.js'
        );
        head.appendChild(script);
      }, []);
    return (
        <section id="meet-us" className="meet-us">
          <h3 className="header-section">Meet the Founders</h3>
          <Line className="line"/>
          <p className="paragraph-section">Book your meeting now</p>
            <div className="meet-us-content">
         
<div className="calendly-inline-widget" data-url="https://calendly.com/onelake/60min?hide_gdpr_banner=1" style={{minWidth:'320px', height:'650px'}}></div>
            </div>
       </section>
    )
}

export default MeetUs;