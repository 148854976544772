import { Link } from "react-scroll";
import {ReactComponent as Logo} from "../assets/images/logo.svg"
import {ReactComponent as HeaderImage} from "../assets/images/header-image.svg"
import {ReactComponent as HeaderEllipse} from "../assets/images/header-ellipse.svg";
import MobileNavbar from "./MobileNavbar";
import { NavLink } from "react-router-dom";

const Header = ({show=true}) => {

    return (
        <div className="header">
            <div>
                <NavLink to="/">
                    <Logo/>
                </NavLink>
                <ul className="navbar">
                    <li>
                    <NavLink to="/">Home</NavLink>
                     </li>
                    <li>
                    <Link to="why-us">Why Us</Link>
                    </li>
                    <li>
                    <Link to="team">Team</Link>
                    </li>
                    <li>
                    <Link to="meet-us">Meet Us</Link>
                    </li>
                    <li>
                    <NavLink to="/impressum">Impressum</NavLink>
                    </li>
                </ul>
                <MobileNavbar/>
            </div>
            {show && <div className="header-content">
                <div>
                    <h2>Everyone can be a <span>Data Star</span>!</h2>
                    <p>Start analyzing your data in minutes without technical knowledge!
The first AI driven datalake in the world.</p>
                </div>
                    <HeaderImage className="svg-header-img"/>
                    <HeaderEllipse className="svg-ellipse"/>

            </div>}

        </div>
    );
}

export default Header;