import { useState } from "react";
import { Link } from "react-scroll";
import {ReactComponent as Menu} from "../assets/images/icon-menu.svg"
import { NavLink } from "react-router-dom";

const MobileNavbar = () => {
    const [show, setShow] = useState(false);
    return (
        <div className="mobile-navbar">
            <Menu onClick={() => setShow(!show)}/>
            <ul className={`mobile-navbar-content ${show ? 'active' : ''}`}>
                    <li>
                    <Link to="home">Home</Link>
                     </li>
                    <li>
                    <Link to="why-us">Why Us</Link>
                    </li>
                    <li>
                    <Link to="team">Team</Link>
                    </li>
                    <li>
                    <Link to="meet-us">Meet Us</Link>
                    </li>
                    <li>
                    <NavLink to="/impressum">Impressum</NavLink>
                    </li>
                </ul>

        </div>
    )

}
export default MobileNavbar;