import {ReactComponent as Line} from "../assets/images/icon-line.svg";
import  First from "../assets/images/why-us-first.png";
import Second from "../assets/images/why-us-second.png";
import  Third from "../assets/images/why-us-third.png";


const WhyUs = () => {

    return (
        <section id="why-us" className="why-us">
          <h3 className="header-section">Why Is oneLake Special</h3>
          <Line className="line"/>
          <p className="paragraph-section">Stop wasting time on data preparation and start analyzing your data right now!</p>
            <div className="why-us-content">
                <div>
                    <img src={First}/>
                    <div>
                    <p className="title">Add all your favourite data sources</p>
                    <p className="content">With oneLake you can add all your data sources in your company with a few clicks. No need for any additional driver or other technical requirements</p>
                    </div>
                </div>
                <div>
                <img src={Second}/>
                <div>
                    <p className="title">Use templates provided by our AI</p>
                    <p className="content">You don't have to create your data models on your own. Our AI analyses your data source and provides to you predefined data models with your data. Of course, you can modify the suggested template or create your own data model as well.</p>
                </div>
                </div>
                <div>
                <img src={Third}/>
                <div>
                    <p className="title">Let the AI do the work for you</p>
                    <p className="content">Our AI has more than 20 years experience in modern data warehouse architectures and data modelling and will help you at each step. You don't have to care about relationships between tables, incremental data loads or data modelling.</p>
                </div>
                </div>

            </div>
       </section>
    )
}

export default WhyUs;