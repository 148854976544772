import Layout from "../layout/Layout";
import WhyUs from "../components/WhyUs";
import Team from "../components/Team";
import MeetUs from "../components/MeetUs";
import "./landingPage.scss";
import "./responsiveness.scss";

const LandingPage = () => {
  return (
    <>
      <Layout>
        <div className="landing-page">
          <WhyUs />
          <Team />
          <MeetUs />
        </div>
      </Layout>
    </>
  );
};
export default LandingPage;
