import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as Linkedin } from "../assets/images/icon-linkedin.svg";
import { ReactComponent as Email } from "../assets/images/icon-email.svg";
import { ReactComponent as Location } from "../assets/images/icon-location.svg";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="left">
        <div>
          <Logo className="logo" />
          <p>Connect with us on LinkedIn</p>
        </div>
        <a target="_blank" href="https://www.linkedin.com/company/onelake/">
          <Linkedin className="linkedin" />
        </a>
      </div>
      <div className="center">
        <ul className="navbar">
          <li>
            <Link to="home">Home</Link>
          </li>
          <li>
            <Link to="why-us">Why Us</Link>
          </li>
          <li>
            <Link to="team">Team</Link>
          </li>
          <li>
            <Link to="meet-us">Meet Us</Link>
          </li>
          <li>
            <NavLink to="/impressum">Impressum</NavLink>
          </li>
          <li>
            <NavLink to="/privacy-policy">Privacy policy</NavLink>
          </li>
        </ul>
        <p>© 2021 by oneLake. All rights reserved</p>
      </div>
      <div className="right">
        <p>Contact Us</p>
        <ul>
          <li>
            <Email /> <span>info@onelake.ai</span>
          </li>
          <li style={{ alignItems: "flex-start" }}>
            <Location />
            <span>
              oneLake GmbH
              <br />
              Goldammerweg 1<br />
              44388 Dortmund
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
