import Layout from "../layout/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout show={false}>
      <div className="privacy-policy container">
        <h1>oneLake GmbH Privacy Policy</h1>
        <p>Last Updated: 03.05.2024.</p>
        <h3>1. Introduction</h3>
        <p>
          Welcome to oneLake GmbH ("we," "our," or "us"). This Privacy Policy
          outlines how we collect, use, disclose, and safeguard your personal
          information. By using our services, you agree to the terms of this
          Privacy Policy.
        </p>
        <h3>2. Company Information</h3>
        <ul>
          <li>Full Legal Name: oneLake GmbH</li>
          <li>Physical Address: Goldammerweg 1, 44388 Dortmund, Germany</li>
          <li>Contact for Privacy Inquiries: dataprotection@onelake.de</li>
        </ul>
        <p>
          Welcome to oneLake GmbH, where innovation meets excellence!
          Established with a commitment to delivering cutting-edge solutions,
          oneLake GmbH is a dynamic company located at Goldammerweg 1, 44388
          Dortmund, Germany. Our dedication to providing top-tier services and
          products sets us apart in the industry.
        </p>
        <p>
          At oneLake, we prioritize transparency and data protection. For any
          privacy-related inquiries, please feel free to contact our dedicated
          team at dataprotection@onelake.de. Your privacy is of utmost
          importance to us, and we strive to ensure the confidentiality and
          security of your information.
        </p>
        <p>
          oneLake GmbH operates with a forward-thinking approach, constantly
          evolving to meet the ever-changing demands of the market. Our team of
          skilled professionals is committed to exceeding expectations and
          driving success for our clients.
        </p>
        <p>
          Stay connected with oneLake GmbH for the latest updates, industry
          insights, and exciting developments. Thank you for choosing us as your
          trusted partner on your journey towards success.
        </p>
        <h3>3. Data Collection</h3>
        <p>
          In the realm of data collection, transparency and accountability are
          paramount to building trust with our users. At our organization, we
          adhere to a meticulous data collection process that prioritizes the
          protection and privacy of individuals. Below, we outline the types of
          personal information we collect, the purpose behind it, and our
          commitment to handling sensitive information responsibly.
        </p>
        <p>
          Types of Personal Information Collected: We gather essential
          information that enables us to provide a seamless and personalized
          experience for our users. The data we collect includes:
        </p>
        <ul>
          <li>Name: To address our users personality</li>
          <li>Email Address: For communication and updates</li>
          <li>Phone Number: Facilitates contact for order-related queries</li>
          <li>Address: Essential for our services like invoicing</li>
          <li>
            Contact Information: A comprehensive approach to stay connected
          </li>
        </ul>
        <p>
          Purpose of Collection: Our data collection serves specific and
          legitimate purposes, aligning with the services we offer. The
          information we gather is utilized for:
        </p>
        <ul>
          <li>
            Order Processing: Ensuring smooth and accurate execution of orders
          </li>
          <li>Customer Support: Addressing queries and providing assistance</li>
          <li>
            News and Updates: Keeping users informed about relevant developments
          </li>
        </ul>
        <p>
          Sensitive Information: Respecting user privacy is a core principle in
          our data collection policy. Consequently, we do not seek or retain
          sensitive information, such as health data. Our commitment to
          safeguarding your personal details extends to ensuring that data
          beyond the necessary scope is neither requested nor stored.
        </p>
        <p>
          As we navigate the digital landscape, our users trust is of utmost
          importance. Rest assured, our data collection practices are designed
          to prioritize your privacy, and we continuously evaluate and enhance
          our processes to meet the evolving standards of data protection. Thank
          you for entrusting us with your information as we strive to create a
          secure and user-friendly environment for all.
        </p>
        <h3>4. Data Usage</h3>
        <p>
          At our organization, we place a strong emphasis on the responsible and
          transparent use of the information we collect. The data we gather is
          employed with the primary objective of enhancing user experience and
          delivering a diverse range of services. This commitment is rooted in
          our dedication to ensuring that your privacy and personal information
          are treated with the utmost respect.
        </p>
        <p>
          Our usage of collected information extends beyond mere analytics; it
          is a strategic effort to tailor our offerings to better meet your
          needs and preferences. By analyzing patterns and trends, we can make
          informed decisions to refine and optimize the various services we
          provide. This approach is geared towards creating a more personalized
          and efficient experience for you, our valued user.
        </p>
        <p>
          We are continually working to enhance our data management practices,
          adopting the latest industry standards and best practices to safeguard
          your privacy. As we evolve, our commitment remains steadfast – to
          empower you with the best possible services while respecting your
          privacy and upholding ethical standards.
        </p>
        <h3>5. Data Sharing</h3>
        <p>
          At oneLake, safeguarding your privacy is our top priority. We take
          great care in handling the information you entrust to us, and we want
          to be transparent about our data sharing practices, especially in
          relation to the use of Google Analytics for our website. Here's a
          glimpse into our commitment to protecting your data:
        </p>
        <p>
          <strong>
            1. Limited Sharing with Third-Party Service Providers:
          </strong>
        </p>
        <ul>
          <li>
            To enhance your experience and optimize our services, we use Google
            Analytics, a web analytics service provided by Google. While Google
            Analytics collects data for website analysis, we do not share
            personally identifiable information with third-party service
            providers or governmental authorities. Your data remains
            confidential and is used solely for the purpose of improving our
            website and user experience.
          </li>
        </ul>
        <p>
          <strong>
            2. International Data Transfers with Google Analytics:
          </strong>
        </p>
        <ul>
          <li>
            Google Analytics processes data on servers located in various
            countries, including the United States. However, we've configured
            Google Analytics to prioritize user privacy. We take steps to comply
            with data protection laws and ensure that your data is handled with
            the utmost care. Google, as a data processor, adheres to privacy
            standards, including the EU-U.S. Privacy Shield Framework and
            Swiss-U.S. Privacy Shield Framework for international data
            transfers.
          </li>
        </ul>
        <p>
          Our commitment to data privacy goes beyond mere compliance with
          regulations; it reflects our dedication to earning and maintaining
          your trust. We employ robust security measures to safeguard your
          information, and our policies are designed to prioritize your privacy
          at every step.
        </p>
        <p>
          In the event that any changes are made to our data sharing practices,
          rest assured that we will communicate these changes transparently. We
          believe that you should have control over your personal information,
          and our commitment is to empower you with that control while offering
          the best possible experience with our services.
        </p>
        <h3>6. Cookies and Tracking</h3>
        <p>
          <strong>Types of Cookies:</strong>
        </p>
        <p>
          There are various types of cookies that serve different purposes on
          websites. Performance cookies are designed to collect data on website
          usage, helping improve its overall performance. Functional cookies
          enhance user experience by remembering choices and settings. Security
          cookies contribute to the safety of user data and protect against
          unauthorized access. Additionally, First-Party Cookies are directly
          set by the website you are visiting.
        </p>
        <p>
          <strong>Cookie Preferences:</strong>
        </p>
        <p>
          Managing your cookie preferences empowers you to control the
          information collected about your online activities. You have the
          flexibility to change your cookie preferences at any time. This can be
          done by revisiting the cookie consent banner, which is typically
          displayed when you first visit a website. By adjusting your
          preferences, you can align the use of cookies with your privacy
          concerns and preferences.
        </p>
        <p>
          Furthermore, cookie preferences play a crucial role in balancing
          personalized experiences with privacy considerations. Tailoring your
          settings allows you to strike a balance between enjoying a seamless
          online experience and maintaining control over the data you share. As
          technology evolves, staying informed about cookie practices and
          exercising your preferences helps shape a digital landscape that
          respects individual privacy.
        </p>
        <h3>7. User Rights</h3>
        <p>
          At oneLake, we are committed to safeguarding the privacy and rights of
          our users. To ensure transparency and trust, we have outlined the
          following user rights concerning personal information:
        </p>
        <ol>
          <li>
            Access, Correction, and Deletion: Users retain the right to access,
            correct, and delete their personal information held by oneLake. This
            empowers users to have control over the accuracy and relevance of
            their data within our systems.
          </li>
          <li>
            Exercising Rights: Taking charge of your privacy is simple. Users
            can exercise their rights by managing and deleting cookies through
            their browser settings. This provides a convenient and user-friendly
            way to control the information collected during their online
            interactions with our platform.
          </li>

          <li>
            Cookie Management: Cookies play a crucial role in enhancing user
            experience, but we understand that preferences may vary. Therefore,
            users have the flexibility to manage and delete cookies based on
            their preferences. Adjusting these settings in your browser ensures
            a personalized and comfortable browsing experience.
          </li>

          <li>
            Privacy-Related Requests: For any privacy-related inquiries,
            concerns, or requests, users are encouraged to contact our dedicated
            team at dataprotection@onelake.de. Our Data Protection team is ready
            to assist with any queries regarding the handling of personal
            information, ensuring a swift and responsive resolution to your
            privacy concerns.
          </li>
        </ol>
        <p>
          We believe in empowering our users with the necessary tools and
          information to make informed decisions about their privacy. OneLake
          remains dedicated to upholding the highest standards of data
          protection, providing a secure and trustworthy environment for our
          valued users.
        </p>
        <h3>8. Security Measures</h3>
        <p>
          At the core of our commitment to safeguarding your data, all our
          services are meticulously hosted on certified servers located in
          Germany. These servers are not only geographically strategic but also
          equipped with the latest security updates to ensure the highest level
          of protection against potential threats.
        </p>
        <p>
          Our dedication to maintaining a secure environment extends beyond mere
          infrastructure. In the unfortunate event of a data breach, our
          proactive approach is to prioritize transparency and timely
          communication. Users will be promptly notified via email, allowing
          them to take necessary precautions and stay informed about any
          potential risks.
        </p>
        <p>
          Moreover, our security protocols undergo regular assessments and
          updates to stay ahead of emerging threats in the ever-evolving
          landscape of cybersecurity. We employ industry best practices and work
          closely with experts to fortify our defenses, ensuring that your data
          remains confidential and secure at all times.
        </p>
        <p>
          In addition to robust server security and proactive breach response,
          we continuously invest in the latest encryption technologies. This
          additional layer of protection ensures that sensitive information is
          safeguarded during transmission and storage, providing you with peace
          of mind while using our services.
        </p>
        <p>
          As we navigate the digital age, rest assured that your security is our
          utmost priority. We strive to create an environment where you can
          confidently utilize our services, knowing that every possible measure
          has been taken to protect your valuable data. Our commitment to your
          privacy and security is unwavering, and we are dedicated to staying at
          the forefront of technological advancements to uphold this promise.
        </p>
        <h3>9. GDPR Compliance</h3>
        <p>
          At oneLake, we take the protection of your personal data seriously and
          adhere to the regulations outlined in the General Data Protection
          Regulation (GDPR). Our commitment to transparency and data privacy is
          reflected in our practices, which are in strict accordance with the
          principles set forth by GDPR.
        </p>
        <p>
          <strong>Processing Personal Data:</strong>
        </p>
        <p>
          In accordance with Article 6 of the GDPR, we ensure that all
          processing of personal data is grounded in lawful bases. Whether it be
          the necessity of processing for the performance of a contract,
          compliance with a legal obligation, protection of vital interests,
          consent, the performance of a task carried out in the public interest
          or in the exercise of official authority, we are dedicated to
          upholding the legal foundations for processing your information.
        </p>
        <p>
          <strong>Storage Duration:</strong>
        </p>
        <p>
          Your personal data is retained only for the duration necessary to
          fulfill the purposes for which it was collected, as outlined in our
          comprehensive Privacy Policy. Whether it's for the provision of our
          services, compliance with legal obligations, or safeguarding our
          legitimate interests, we carefully determine the appropriate storage
          period to ensure that your data is not held for longer than necessary.
        </p>
        <p>
          <strong>User Rights:</strong>
        </p>
        <p>
          We respect your rights regarding your personal data. As per the GDPR,
          you have the right to access, rectify, or erase your personal
          information, as well as the right to restrict or object to its
          processing. If you believe that your data protection rights have been
          violated, you also have the right to lodge a complaint with a
          supervisory authority. We encourage you to reach out to our Data
          Protection Officer at dataprotection@onelake.de to address any
          concerns or exercise your rights.
        </p>
        <p>
          <strong>Complaints and Supervisory Authority:</strong>
        </p>
        <p>
          We understand the importance of accountability, and we are committed
          to addressing any concerns you may have regarding the processing of
          your personal data. In the event that you feel your rights have been
          infringed upon, you have the right to lodge a complaint with the
          supervisory authority in your jurisdiction. Our team is available to
          cooperate with the supervisory authorities and provide any necessary
          information to resolve the matter promptly.
        </p>
        <p>
          Your trust is paramount to us, and we strive to continuously enhance
          our data protection measures to meet and exceed the expectations set
          by GDPR. If you have any questions or require further clarification
          regarding our GDPR compliance, please do not hesitate to contact our
          Data Protection Officer.
        </p>
        <h3>10. Privacy Disclosure for oneLake</h3>
        <p>
          oneLake ensures the secure handling of user data obtained from Google
          APIs and other sources. We adhere to the Google API Services User Data
          Policy, including the Limited Use requirements. For more information,
          please review the{" "}
          <a href="https://developers.google.com/terms/api-services-user-data-policy">
            Google API Services User Data Policy
          </a>
          .
        </p>
        <p>
          At oneLake, we are committed to protecting the privacy and security of
          our users' data. We want to be transparent about how your data is used
          and shared when you interact with our application.
        </p>
        <h4>Data Collection:</h4>
        <p>We collect the following data from our users:</p>
        <p>
          <strong>User Email: </strong>We collect user email addresses to
          facilitate communication and account management.
        </p>
        <p>
          <strong>Google Campaign Data: </strong>
          We collect campaign data from Google APIs to provide advertising
          analytics and insights to our users.
        </p>

        <h4>Sharing with Third Party AI Platforms</h4>

        <p>
          Our application utilizes certain artificial intelligence (AI) modules,
          specifically GPT 3.5 and GPT, to enhance functionality and provide
          valuable insights to our users While we do not directly share user
          data with AI platforms, we may share anonymized and aggregated
          campaign data for analysis purposes.
        </p>
        <h4>Data Security:</h4>
        <p>
          We employ industry-standard security measures to protect the data
          collected from our users against unauthorized access, disclosure,
          alteration, or destruction.
        </p>

        <h3>11. Updates to the Privacy Policy</h3>
        <p>
          We value your privacy and strive to keep you informed about any
          changes to our Privacy Policy. To ensure transparency and openness, we
          have implemented the following practices for updating our privacy
          policy:
        </p>
        <ul>
          <li>
            Email Notifications: Users will receive timely notifications
            regarding any modifications or updates to our Privacy Policy via
            email. This ensures that you stay well-informed about any changes
            that may affect your personal information and how we handle it. We
            understand the importance of keeping you in the loop, and our
            commitment to communication is aimed at maintaining your trust.
          </li>
          <li>
            Regular Updates: In our ongoing effort to adapt to the evolving
            digital landscape and enhance user experience, we anticipate
            updating our privacy policy every six months. This proactive
            approach allows us to stay ahead of industry developments,
            incorporate new technologies responsibly, and align our policies
            with the latest privacy standards. By keeping our policy current, we
            aim to provide you with the most relevant and up-to-date information
            regarding the protection of your data.
          </li>
        </ul>
        <p>
          As we continue to evolve and improve our services, we want you to be
          confident that your privacy is our priority. These updates not only
          reflect legal requirements but also our commitment to maintaining a
          secure and trustworthy platform for your online activities. We
          appreciate your trust in us and look forward to serving you with the
          utmost respect for your privacy.
        </p>
        <p>
          For any questions or concerns regarding this Privacy Policy, please
          contact us at dataprotection@onelake.de.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
