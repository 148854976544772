import Header from "./Header";
import Footer from "./Footer";
import "./layout.scss";

const Layout = ({show,children}) => {
    return (
    <>
        <Header show={show}/>
        {children}
        <Footer/>
    </>
    )
}

export default Layout;