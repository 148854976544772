import React from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Impressum from "./pages/Impressum";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" render={() => <Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
